import styled, { css } from 'styled-components'
import { Menu } from '@mui/material'

export const MenuStyled = styled(Menu)(
  ({ theme }) => css`
    .MuiPaper-root {
      margin-top: ${theme.spacing(0.5)};
    }
  `
)
