import React, { useState } from 'react'
import { HtmlMouseEventHandler } from '@digital-magic/react-common'
import { Fade, MenuProps as MuiMenuProps } from '@mui/material'
import { MenuStyled } from './MenuTrigger.styles'

export type MenuTriggerProps = Omit<MuiMenuProps, 'open' | 'anchorEl' | 'onClose' | 'onClick' | 'children'> & {
  children: (setAnchor: (el: HTMLElement | null) => void, open: boolean) => React.ReactNode
  renderMenu: () => React.ReactNode
}

export const MenuTrigger: React.FC<MenuTriggerProps> = ({ children, renderMenu, ...muiMenuProps }) => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)

  const open = Boolean(anchor)

  const handleClose: HtmlMouseEventHandler = (e): void => {
    e.stopPropagation()
    setAnchor(null)
  }

  return (
    <>
      {children(setAnchor, open)}
      <MenuStyled
        {...muiMenuProps}
        open={open}
        anchorEl={anchor}
        onClose={handleClose}
        onClick={handleClose}
        TransitionComponent={Fade}
        disableScrollLock
      >
        {renderMenu()}
      </MenuStyled>
    </>
  )
}
